<template>
  <div class="tw-flex tw-items-center ltr:tw-pr-5 rtl:tw-pl-5">
    <template v-if="branding">
      <div class="tw-flex md:tw-flex-col tw-justify-center tw-items-center md:tw-gap-2">
        <div class="tw-flex tw-items-center tw-gap-3">
          <a :href="branding.partner_url" rel="noopener">
            <img
              :src="branding.primary_logo"
              :alt="branding.name"
              :class="coBrandingImageClasses"
              height="auto"
              width="auto"
              loading="eager"
            />

            <template v-if="branding.secondary_logo">
              <img
                :src="branding.secondary_logo"
                :alt="branding.name"
                :class="coBrandingImageClasses"
                height="auto"
                width="auto"
                loading="eager"
              />
            </template>
          </a>
        </div>

        <div
          class="tw-flex md:tw-hidden before:tw-content-['']"
          :class="[
            'tw-relative tw-items-center tw-gap-[17px]',
            'ltr:tw-pl-2.5 ltr:tw-ml-2.5 rtl:tw-pr-2.5 rtl:tw-mr-2.5',
            'before:tw-absolute ltr:before:tw-left-0 rtl:before:tw-right-0 before:tw-h-[44px] before:tw-w-[1px] before:tw-bg-zinc-300',
          ]"
        >
          <nuxt-link class="tw-inline-flex" :to="localePath({ name: 'index' })">
            <nuxt-img
              src="/assets/images/svg/powered-by-airalo-mobile-logo.svg"
              alt="Powered by Airalo mobile logo"
              height="47"
              width="62"
              class="tw-w-auto"
              loading="eager"
            />
          </nuxt-link>
        </div>

        <nuxt-link class="tw-hidden md:tw-inline-flex" :to="localePath({ name: 'index' })">
          <nuxt-img
            src="/assets/images/svg/powered-by-airalo-desktop-logo.svg"
            alt="Powered by Airalo desktop logo"
            height="21"
            width="150"
            class="tw-w-auto"
            loading="eager"
          />
        </nuxt-link>
      </div>
    </template>

    <template v-else>
      <div
        class="tw-w-full md:tw-items-start md:tw-w-auto"
        :class="{
          'tw-flex tw-justify-center tw-items-center': !isAuthenticated || isCheckoutPage,
        }"
      >
        <nuxt-link class="tw-inline-flex" :to="localePath({ name: 'index' })">
          <nuxt-img
            src="/assets/images/svg/Logo.svg"
            alt="Airalo logo"
            data-testid="airalo-logo"
            loading="eager"
            class="tw-h-[30px] tw-w-[102px] md:tw-h-[44px] md:tw-w-[149px]"
          />
        </nuxt-link>
      </div>
    </template>

    <div
      :class="[
        'tw-hidden lg:tw-block',
        'tw-border-solid tw-border-0 tw-border-zinc-300 ltr:tw-border-l rtl:tw-border-r',
        'tw-py-2.5 ltr:tw-pl-5 ltr:tw-ml-5 rtl:tw-pr-5 rtl:tw-mr-5',
      ]"
    >
      <nuxt-link
        class="tw-flex tw-content-start tw-items-center !tw-no-underline !tw-text-primary"
        :to="localePath({ name: 'index' })"
      >
        <div class="airalo-icon-store tw-text-zinc-500 ltr:tw-mr-2.5 rtl:tw-ml-2.5" />
        <span>{{ $t('components.main.navigation.store') }}</span>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ui-header-logo',
    props: {
      branding: {
        type: Object | null,
        required: true,
      },
      isCheckoutPage: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      isAuthenticated() {
        return this.$store.getters.is_authenticated
      },
      coBrandingImageClasses() {
        if (this.branding?.secondary_logo) {
          return 'tw-max-w-[50px] tw-max-h-[50px] md:tw-max-w-[72px] md:tw-max-h-[72px]'
        }

        return 'tw-max-w-[75px] tw-max-h-[50px] md:tw-max-w-[149px] md:tw-max-h-[72px]'
      },
    },
  }
</script>
