import { datadogRum } from '@datadog/browser-rum'

export default ({ app }) => {
  let firebaseSamplingRate = 1

  if (app.$featureFlag.isActive('datadog_rum_sample_rate')) {
    firebaseSamplingRate = Number(app.$featureFlag.value('datadog_rum_sample_rate'))
  }

  if (process.env.DATADOG_APPLICATION_ID) {
    datadogRum.init({
      applicationId: process.env.DATADOG_APPLICATION_ID,
      clientToken: process.env.DATADOG_CLIENT_TOKEN,
      site: process.env.DATADOG_SITE,
      service: process.env.DATADOG_SERVICE,
      env: process.env.DATADOG_ENV,
      // version: '1.0.0',
      sessionSampleRate: firebaseSamplingRate || 1,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [/^(https?:\/\/)?([a-zA-Z0-9-]+\.)?airalo\.com(\/.*)?$/],
    })
  }
}
