var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tw-flex tw-items-center ltr:tw-pr-5 rtl:tw-pl-5"
  }, [_vm.branding ? [_c('div', {
    staticClass: "tw-flex md:tw-flex-col tw-justify-center tw-items-center md:tw-gap-2"
  }, [_c('div', {
    staticClass: "tw-flex tw-items-center tw-gap-3"
  }, [_c('a', {
    attrs: {
      "href": _vm.branding.partner_url,
      "rel": "noopener"
    }
  }, [_c('img', {
    class: _vm.coBrandingImageClasses,
    attrs: {
      "src": _vm.branding.primary_logo,
      "alt": _vm.branding.name,
      "height": "auto",
      "width": "auto",
      "loading": "eager"
    }
  }), _vm._v(" "), _vm.branding.secondary_logo ? [_c('img', {
    class: _vm.coBrandingImageClasses,
    attrs: {
      "src": _vm.branding.secondary_logo,
      "alt": _vm.branding.name,
      "height": "auto",
      "width": "auto",
      "loading": "eager"
    }
  })] : _vm._e()], 2)]), _vm._v(" "), _c('div', {
    staticClass: "tw-flex md:tw-hidden before:tw-content-['']",
    class: ['tw-relative tw-items-center tw-gap-[17px]', 'ltr:tw-pl-2.5 ltr:tw-ml-2.5 rtl:tw-pr-2.5 rtl:tw-mr-2.5', 'before:tw-absolute ltr:before:tw-left-0 rtl:before:tw-right-0 before:tw-h-[44px] before:tw-w-[1px] before:tw-bg-zinc-300']
  }, [_c('nuxt-link', {
    staticClass: "tw-inline-flex",
    attrs: {
      "to": _vm.localePath({
        name: 'index'
      })
    }
  }, [_c('nuxt-img', {
    staticClass: "tw-w-auto",
    attrs: {
      "src": "/assets/images/svg/powered-by-airalo-mobile-logo.svg",
      "alt": "Powered by Airalo mobile logo",
      "height": "47",
      "width": "62",
      "loading": "eager"
    }
  })], 1)], 1), _vm._v(" "), _c('nuxt-link', {
    staticClass: "tw-hidden md:tw-inline-flex",
    attrs: {
      "to": _vm.localePath({
        name: 'index'
      })
    }
  }, [_c('nuxt-img', {
    staticClass: "tw-w-auto",
    attrs: {
      "src": "/assets/images/svg/powered-by-airalo-desktop-logo.svg",
      "alt": "Powered by Airalo desktop logo",
      "height": "21",
      "width": "150",
      "loading": "eager"
    }
  })], 1)], 1)] : [_c('div', {
    staticClass: "tw-w-full md:tw-items-start md:tw-w-auto",
    class: {
      'tw-flex tw-justify-center tw-items-center': !_vm.isAuthenticated || _vm.isCheckoutPage
    }
  }, [_c('nuxt-link', {
    staticClass: "tw-inline-flex",
    attrs: {
      "to": _vm.localePath({
        name: 'index'
      })
    }
  }, [_c('nuxt-img', {
    staticClass: "tw-h-[30px] tw-w-[102px] md:tw-h-[44px] md:tw-w-[149px]",
    attrs: {
      "src": "/assets/images/svg/Logo.svg",
      "alt": "Airalo logo",
      "data-testid": "airalo-logo",
      "loading": "eager"
    }
  })], 1)], 1)], _vm._v(" "), _c('div', {
    class: ['tw-hidden lg:tw-block', 'tw-border-solid tw-border-0 tw-border-zinc-300 ltr:tw-border-l rtl:tw-border-r', 'tw-py-2.5 ltr:tw-pl-5 ltr:tw-ml-5 rtl:tw-pr-5 rtl:tw-mr-5']
  }, [_c('nuxt-link', {
    staticClass: "tw-flex tw-content-start tw-items-center !tw-no-underline !tw-text-primary",
    attrs: {
      "to": _vm.localePath({
        name: 'index'
      })
    }
  }, [_c('div', {
    staticClass: "airalo-icon-store tw-text-zinc-500 ltr:tw-mr-2.5 rtl:tw-ml-2.5"
  }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.$t('components.main.navigation.store')))])])], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }